import { render, staticRenderFns } from "./tchrSituation.vue?vue&type=template&id=7bc2290a&"
import script from "./tchrSituation.vue?vue&type=script&lang=js&"
export * from "./tchrSituation.vue?vue&type=script&lang=js&"
import style0 from "./tchrSituation.vue?vue&type=style&index=0&id=7bc2290a&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports